import React, { Component } from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import FormButtons from '../../formbuttons';
import style from '../../index.module.css';
import StepButtons from '../../stepbuttons';
import { getEntity } from '../../../../actions/exhibitor/exhibitor-actions.js';
import {
    updateStorage,
    insertStorage,
} from '../../../../actions/exhibitor/organisation/index.js';
import {
    showAlert,
    SUCCESS,
    SUCCESS_MSG,
    ERROR,
    validateForm,
    ERROR_MSG_BACKEND_SAVE,
    ERROR_MSG_BACKEND_FETCH,
} from '../../alerts';
import { getOrganisation } from '../../../../actions/exhibitor/organisation';
import { VERTRAG__VERTRAG_LOGO__JA } from '../../../../backend-id-constants.js';
import { FEATURE_FLAGS } from '../../../../global_constants.js';

class ExhibitorStorage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
        };
    }

    resetForm() {
        this.setState({
            submitSuccess: false,
        });
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                if (
                    this.props.organisation &&
                    !Object.keys(this.props.organisation).length
                ) {
                    return this.props
                        .dispatch(getOrganisation('organisation'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .then(() => {
                if (
                    this.props.storage &&
                    !Object.keys(this.props.storage).length
                ) {
                    return this.props
                        .dispatch(getEntity('storage'))
                        .then(null, (_e) => Promise.reject());
                }
            })
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_FETCH);
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    submit(values) {
        validateForm(this, values, ['kzLagerbedarfGefahrstoffe']);
        Promise.resolve()
            .then(() => {
                // if updating an existing Lagerflaeche
                if (this.props.storage.length > 0) {
                    return this.props
                        .dispatch(updateStorage(this.props.storage[0], values))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                } else {
                    // if creating a new Lagerflaeche
                    return this.props
                        .dispatch(insertStorage({ ...values }))
                        .then(null, (_e) =>
                            Promise.reject({ _error: ERROR_MSG_BACKEND_SAVE }),
                        );
                }
            })
            .then(() =>
                this.setState({
                    submitSuccess: true,
                }),
            )
            .then(() => showAlert(SUCCESS, SUCCESS_MSG))
            .catch((e) => {
                showAlert(ERROR, e?._error || ERROR_MSG_BACKEND_SAVE);
                this.setState({ submitSuccess: false });
                this.props.dispatch(stopSubmit('storage', e));
            });
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>Lagerfläche bearbeiten</h1>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <fieldset>
                            <legend>
                                Jedem Aussteller kann bei Bedarf eine
                                Lagerfläche für EURO Paletten (1,20 m x 0,80 m,
                                keine Regale o. ä., kein Einlagern von Leergut
                                bzw. Verpackungsmaterialien) zur Verfügung
                                gestellt werden. Bitte beschränken Sie sich auf
                                einen minimal erforderlichen Lagerplatz, da
                                Lagerfläche nur begrenzt zur Verfügung steht.
                                Ihren Bedarf melden Sie bitte bis zum 9. Mai
                                2024 hier im Ausstellerportal an.
                            </legend>
                            {/* NOTE: End of Deadline means the Design must be changed from inputs to pure text */}
                            <label
                                htmlFor='anzahlPaletten'
                                className={style.bold}
                            >
                                <span>Anzahl der Paletten</span>
                            </label>
                            <Field
                                component='select'
                                name='anzahlPaletten'
                                id='anzahlPaletten'
                                ref={this.arrivalTime}
                            >
                                <option value='0'>0</option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                            </Field>
                            <label htmlFor='anmerkungen' className={style.bold}>
                                <span>
                                    Anmerkungen (Mehrbedarf, Anlieferung etc.)
                                </span>
                            </label>
                            <Field
                                component='input'
                                type='text'
                                id='anmerkungen'
                                name='anmerkungen'
                                ref={this.input}
                            />
                            <div className={style.space} />
                            <legend className={style.bold}>
                                Haben Sie Lagerbedarf für Gefahrstoffe /
                                Gasflaschen (*)
                            </legend>
                            <Field
                                component='input'
                                type='radio'
                                name='kzLagerbedarfGefahrstoffe'
                                id='radioYes2'
                                value='1'
                                required
                                ref={this.input}
                            />
                            <label htmlFor='radioYes2'>
                                <span>Ja</span>
                            </label>
                            <Field
                                component='input'
                                type='radio'
                                id='radioNo2'
                                name='kzLagerbedarfGefahrstoffe'
                                value='0'
                                required
                                ref={this.input}
                            />
                            <label htmlFor='radioNo2'>
                                <span>Nein</span>
                            </label>
                            <FormButtons
                                hideBtn={true}
                                handleSubmit={handleSubmit}
                                submit={this.submit.bind(this)}
                            />
                            <legend>(*) = Pflichtfeld</legend>
                        </fieldset>
                    </form>
                    <StepButtons
                        back='/organisation/exhibitor-catering'
                        labelBack='Ausstellercatering'
                        to={
                            FEATURE_FLAGS.banner && this.props.hasVertragLogo
                                ? '/organisation/banner-print'
                                : undefined
                        }
                        labelForward={
                            FEATURE_FLAGS.banner && this.props.hasVertragLogo
                                ? 'Hallenbanner'
                                : undefined
                        }
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, _props) => {
    const defaultValue = {
        anmerkungen: '',
        anzahlPaletten: 0,
    };

    const storage =
        Array.isArray(state.storage) && state.storage.length > 0
            ? state.storage[0]
            : null;
    let initialValues = defaultValue;
    if (storage) {
        initialValues = {
            anmerkungen: storage.anmerkungen || defaultValue.anmerkungen,
            anzahlPaletten:
                storage.anzahlPaletten || defaultValue.anzahlPaletten,
            kzLagerbedarfGefahrstoffe:
                storage.kzLagerbedarfGefahrstoffe === 1 ? '1' : '0',
        };
    }

    let hasVertragLogo = false;
    if (
        state.contract &&
        Array.isArray(state.contract) &&
        state.contract.length > 0
    ) {
        const contract = state.contract[0];
        hasVertragLogo = contract.idVertragLogo === VERTRAG__VERTRAG_LOGO__JA;
    }

    return {
        initialValues: initialValues || defaultValue,
        storage: state.storage || [],
        organisation: state.organisation || [],
        ie_begin: state.deadlines?.ie_begin,
        hasVertragLogo,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null),
)(
    reduxForm({
        form: 'storage',
        destroyOnUnmount: false,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    })(ExhibitorStorage),
);
