import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { withRouter } from 'react-router-dom';
import style from '../index.module.css';
import FormButtons from '../formbuttons';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true,
        };
        this.input = React.createRef();
    }
    componentDidMount() {
        Promise.resolve().then(() => {
            return this.props.dispatch({ type: 'LOGOUT' });
        });
        if (this.props.password || this.props.username) {
            this.props.change('password', this.props.password);
            this.props.change('username', this.props.username);
        }
    }
    submit = async (values) => {
        if (!values.username) {
            throw new SubmissionError({
                _error: 'Benutzername darf nicht leer sein',
            });
        } else if (!values.password) {
            throw new SubmissionError({
                _error: 'Passwort darf nicht leer sein',
            });
        }

        await this.props.dispatch({ type: 'LOGOUT' });

        try {
            if (values.username && values.password) {
                return this.props.dispatch(
                    push({
                        pathname: '/check',
                        state: {
                            username: values.username,
                            password: values.password,
                        },
                    }),
                );
            }
        } catch (e) {
            console.error('error checking code, promise rejected:', e);
            this.setState({
                state: e?.state ? e.state : 'invalid',
            });
        }
    };

    render() {
        const { handleSubmit } = this.props;
        const submitValues = handleSubmit(this.submit.bind(this));

        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1 className={style.welcomePage}>
                        <b>Mach doch einfach!</b>
                    </h1>
                    <p>Willkommen beim Ausstellerportal der IdeenExpo.</p>
                </div>
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <fieldset>
                        <legend>Bitte melden Sie sich an!</legend>
                        <Field
                            component='input'
                            name='username'
                            type='text'
                            placeholder='Benutzername'
                            ref={this.input}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    submitValues();
                                }
                            }}
                            autoFocus
                            className={style.welcomePageInput}
                        />
                        <Field
                            component='input'
                            name='password'
                            type='password'
                            placeholder='Passwort'
                            ref={this.input}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    submitValues();
                                }
                            }}
                            className={style.welcomePageInput}
                        />
                        <FormButtons
                            submitName='Anmelden'
                            hideBtn={true}
                            handleSubmit={handleSubmit}
                            submit={submitValues}
                        />
                    </fieldset>
                </form>
                <p>
                    Hinweis: Die Zugangsdaten zur Aussteller-Datenbank haben Sie
                    von Ihrem IdeenExpo-Ansprechpartner erhalten. Sollten Sie
                    keine Benutzerdaten vorliegen haben, so wenden Sie sich an
                    Ihren Ansprechpartner bei der IdeenExpo. Jeder Aussteller
                    hat einen Benutzernamen. Falls Sie weitere Personen mit dem
                    Einpflegen von Daten beauftragen wollen, so bitten wir um
                    vertrauliche Weitergabe dieser Daten. Release: 2024-04-17
                </p>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default compose(
    withRouter,
    connect(
        (s) => ({
            reload: s.router?.location?.state?.reload || false,
        }),
        mapDispatchToProps,
    ),
)(
    reduxForm({
        form: 'exhibitorlogin',
        destroyOnUnmount: true,
    })(Login),
);
