import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';
import Question from '../../common/question';

class SchoolRegistrationValidate extends Question {
    constructor(props) {
        super(props, ['form.school_registration.values'], {
            valid: false,
        });
        this.gdpr = React.createRef();
    }
    handleInput() {
        this.setState({}, () => {
            this.validate();
        });
    }
    validate() {
        this.setState({
            valid: this.gdpr.current?.value,
        });
    }
    renderQuestion() {
        const form = this.props.formdata;
        return (
            <div className={style.question}>
                <h1>
                    Ihre <b>Daten</b>
                </h1>
                <p>
                    Folgende Daten haben Sie eingegeben. Bitte überprüfen Sie
                    diese und korrigieren Sie sie bei Bedarf. Wenn alles korrekt
                    ist, bestätigen Sie bitte die Datenschutzvereinbarung am
                    Ende der Seite und klicken dann auf „Absenden“!
                </p>
                <fieldset>
                    <div className='validate-entry'>
                        <span>
                            <b>Name der Schule:</b>
                        </span>
                        <span>
                            {form.school_registration.values.schoolname}
                        </span>
                    </div>
                    <div className='validate-entry'>
                        <span>
                            <b>Schulform:</b>
                        </span>
                        <span>
                            {form.school_registration.values.schoolType}
                        </span>
                    </div>
                    <div className='validate-entry'>
                        <span>
                            <b>Adresse:</b>
                        </span>
                        <span>
                            {form.school_registration.values.street},{' '}
                            {form.school_registration.values.zipcode}{' '}
                            {form.school_registration.values.city}
                        </span>
                    </div>
                    {/* <div className="validate-entry"><span><b>Postleitzahl:</b></span><span>{form.school_registration.values.zipcode}</span></div>
          <div className="validate-entry"><span><b>Ort:</b></span><span>{form.school_registration.values.city}</span></div> */}
                    <div className='validate-entry'>
                        <span>
                            <b>E-Mail der Schule:</b>
                        </span>
                        <span>
                            {form.school_registration.values.emailschool}
                        </span>
                    </div>
                    <hr />
                    <div className='validate-entry'>
                        <span>
                            <b>Antragsteller:</b>
                        </span>
                        <span>
                            {form.school_registration.values.salutation}{' '}
                            {form.school_registration.values.firstname}{' '}
                            {form.school_registration.values.lastname}
                        </span>
                    </div>
                    <div className='validate-entry'>
                        <span>
                            <b>Position des Antragstellers:</b>
                        </span>
                        <span>{form.school_registration.values.position}</span>
                    </div>
                    {/* <div className="validate-entry"><span><b>Nachname des Antragstellers:</b></span><span>{form.school_registration.values.lastname}</span></div>
          <div className="validate-entry"><span><b>Vorname des Antragstellers:</b></span><span>{form.school_registration.values.firstname}</span></div> */}
                    <div className='validate-entry'>
                        <span>
                            <b>E-Mail des Antragstellers:</b>
                        </span>
                        <span>{form.school_registration.values.email}</span>
                    </div>
                    <div className='validate-entry'>
                        <span>
                            <b>Telefon des Antragstellers:</b>
                        </span>
                        <span>{form.school_registration.values.phone}</span>
                    </div>

                    <div className='row'>
                        <Field
                            component='input'
                            type='checkbox'
                            id='gdpr'
                            name='gdpr'
                            ref={this.gdpr}
                            onChange={(_e) => this.handleInput()}
                        />
                        <label htmlFor='gdpr'>
                            <span>
                                Ich bin mit der Speicherung der o. g. Daten
                                einverstanden.
                            </span>
                        </label>
                    </div>
                </fieldset>
                <StepButtons
                    back='/school-registration'
                    to='/sending-school'
                    toText='Absenden'
                    disabled={!this.state.valid}
                />
            </div>
        );
    }
}

export default reduxForm({
    form: 'school_registration_validate',
    destroyOnUnmount: false,
})(connect((s) => ({ formdata: s.form, ...s }))(SchoolRegistrationValidate));
