import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { PURGE } from 'redux-persist';

import { determineAuthForRoles } from '../functions.js';
import { VERTRAG__VERTRAG_LOGO__JA } from '../../../backend-id-constants.js';
import { FEATURE_FLAGS } from '../../../global_constants.js';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            visible: false,
            loggedIn: this.props.loggedIn || true,
        };
    }
    componentDidMount() {
        setTimeout((_) =>
            this.setState((_state, _props) => ({ visible: true })),
        );
    }
    toggle(_e) {
        this.setState((state, _props) => ({ open: !state.open }));
    }

    scrollToTop() {
        document.querySelector('.simplebar-content-wrapper').scrollTo(0, 0);
    }

    toggleAndScrollToTop() {
        this.toggle();
        this.scrollToTop();
    }

    onPurgeStoredState() {
        this.toggle();
        this.setState((state) => ({
            loggedIn: !state.loggedIn,
        }));

        Promise.resolve()
            .then(() => {
                this.props.dispatch({
                    type: PURGE,
                    key: `persist: ideenexpo-${process.env.REACT_APP_IDEENEXPO}`,
                    result: () => null,
                });
            })
            .then(() => {
                this.props.dispatch({
                    type: 'LOGOUT',
                });
            })
            .then(() => {
                window.location.reload();
            });
    }

    render() {
        const indexValue = this.state.open ? '0' : '-1';

        const tabindex = { tabIndex: indexValue };

        return (
            <>
                {this.props.pathname !== '/login' &&
                this.props.pathname !== '/check' ? (
                    <div
                        id='exhibitor-menu'
                        className={this.state.open ? ' opened' : ''}
                    >
                        {this.state.visible && (
                            <div className={'content'}>
                                <div className='submenu'>
                                    <Link
                                        to='/home'
                                        onClick={() =>
                                            this.toggleAndScrollToTop()
                                        }
                                        {...tabindex}
                                    >
                                        <span>Home</span>
                                    </Link>
                                </div>
                                {this.props.hasBoothAuthorization ? (
                                    <div className='submenu'>
                                        <Link
                                            to='/booths'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Stände/Räume</span>
                                        </Link>
                                        <Link
                                            to='/booths/exhibits'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Exponate</span>
                                        </Link>
                                        <Link
                                            to='/booths/exhibit-boards'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Exponattafeln</span>
                                        </Link>
                                        <Link
                                            to='/booths/steles'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Stelen</span>
                                        </Link>
                                        <Link
                                            to='/booths/furnishings'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Mobiliar</span>
                                        </Link>
                                        {/*<Link to="/booths/technic"  className="second-level-menu" onClick={()=>(this.toggle(),this.scrollToTop())} {...tabindex}><span>Technik</span></Link>*/}
                                    </div>
                                ) : null}
                                {this.props.hasWorkshopAuthorization ? (
                                    <div className='submenu'>
                                        <Link
                                            to='/veranstaltungen'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Veranstaltungen</span>
                                        </Link>
                                        <Link
                                            to='/workshops'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Workshops</span>
                                        </Link>
                                        <Link
                                            to='/stage-contributions'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Bühnenbeiträge</span>
                                        </Link>
                                        <Link
                                            to='/booth-visits'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Standbesuche</span>
                                        </Link>
                                    </div>
                                ) : null}
                                {this.props.hasLiveprogramAuthorization ? (
                                    <div className='submenu'>
                                        <Link
                                            to='/live-program'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>LiveProgramm</span>
                                        </Link>

                                        {this.props.hasLiveProgram ? (
                                            <Link
                                                to='/live-program/stage-seven'
                                                className='second-level-menu'
                                                onClick={() =>
                                                    this.toggleAndScrollToTop()
                                                }
                                                {...tabindex}
                                            >
                                                <span>BühneSieben</span>
                                            </Link>
                                        ) : null}
                                        {this.props.hasLiveProgram ? (
                                            <Link
                                                to='/live-program/show-stage'
                                                className='second-level-menu'
                                                onClick={() =>
                                                    this.toggleAndScrollToTop()
                                                }
                                                {...tabindex}
                                            >
                                                <span>ShowBühne</span>
                                            </Link>
                                        ) : null}
                                    </div>
                                ) : null}
                                {this.props.hasClubzukunftAuthorization &&
                                this.props.hasCarreerStageAuthorization ? (
                                    <div className='submenu'>
                                        <Link
                                            to='/clubzukunft'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>ClubZukunft</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-stage'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>KarriereBühne</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>KarriereMarkt</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market/contact'
                                            className='third-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Kontaktdaten</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market/sites'
                                            className='third-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Standorte</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market/training'
                                            className='third-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Ausbildungsberufe</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market/dual-study'
                                            className='third-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Duales Studium</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market/studyfields'
                                            className='third-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Studienrichtungen</span>
                                        </Link>
                                    </div>
                                ) : null}
                                {this.props.hasClubzukunftAuthorization &&
                                this.props.hasCarreerStageAuthorization ===
                                    false ? (
                                    <div className='submenu'>
                                        <Link
                                            to='/clubzukunft'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>ClubZukunft</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>KarriereMarkt</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market/contact'
                                            className='third-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Kontaktdaten</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market/sites'
                                            className='third-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Standorte</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market/training'
                                            className='third-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Ausbildungsberufe</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market/dual-study'
                                            className='third-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Duales Studium</span>
                                        </Link>
                                        <Link
                                            to='/clubzukunft/career-market/studyfields'
                                            className='third-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Studienrichtungen</span>
                                        </Link>
                                    </div>
                                ) : null}
                                {this.props.hasOrganisationAuthorization ? (
                                    <div className='submenu'>
                                        <Link
                                            to='/organisation'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Organisation</span>
                                        </Link>
                                        <Link
                                            to='/organisation/exhibitor-passes'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Ausstellerausweise</span>
                                        </Link>
                                        <Link
                                            to='/organisation/exhibitor-catering'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Ausstellercatering</span>
                                        </Link>
                                        <Link
                                            to='/organisation/storage'
                                            className='second-level-menu'
                                            onClick={() =>
                                                this.toggleAndScrollToTop()
                                            }
                                            {...tabindex}
                                        >
                                            <span>Lagerfläche</span>
                                        </Link>
                                        {FEATURE_FLAGS.banner &&
                                            this.props.hasVertragLogo && (
                                                <>
                                                    <Link
                                                        to='/organisation/banner-print'
                                                        className='second-level-menu'
                                                        onClick={() =>
                                                            this.toggleAndScrollToTop()
                                                        }
                                                        {...tabindex}
                                                    >
                                                        <span>
                                                            Hallenbanner
                                                        </span>
                                                    </Link>
                                                    <Link
                                                        to='/organisation/banner-digital'
                                                        className='second-level-menu'
                                                        onClick={() =>
                                                            this.toggleAndScrollToTop()
                                                        }
                                                        {...tabindex}
                                                    >
                                                        <span>
                                                            Webseite/App
                                                        </span>
                                                    </Link>
                                                </>
                                            )}
                                    </div>
                                ) : null}
                                <div className='submenu'>
                                    <Link
                                        to={{ pathname: '/login' }}
                                        replace
                                        onClick={() =>
                                            this.onPurgeStoredState()
                                        }
                                        {...tabindex}
                                    >
                                        <span>
                                            {this.props.loggedIn
                                                ? 'Logout'
                                                : 'Login'}
                                        </span>
                                    </Link>
                                    <Link
                                        to={{ pathname: '/newPassword' }}
                                        onClick={() => this.toggle()}
                                        replace
                                        {...tabindex}
                                    >
                                        <span>
                                            {this.props.loggedIn
                                                ? 'Neues Passwort'
                                                : ''}
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        )}
                        <div className='trigger' onClick={() => this.toggle()}>
                            <span>Menu</span>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state, _props) => {
    const authForRoles = determineAuthForRoles(state);
    let hasLiveProgram = false;
    let hasVertragLogo = false;
    if (
        state.contract &&
        Array.isArray(state.contract) &&
        state.contract.length > 0
    ) {
        const contract = state.contract[0];
        hasLiveProgram = contract.liveProgrammDeaktiviert === 0;
        hasVertragLogo = contract.idVertragLogo === VERTRAG__VERTRAG_LOGO__JA;
    }

    return {
        loggedIn: Boolean(state.authenticationDataExhibitor?.accessToken),
        pathname: (state.router || []).location?.pathname,
        ...authForRoles,
        hasLiveProgram,
        hasVertragLogo,
    };
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
