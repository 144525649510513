import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';

import { MAIL_PATTERN, PHONE_PATTERN } from '../../../global_constants';
import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';
import Question from '../../common/question';
import {
    fetchSchoolTypeList,
    fetchZipcodeCities,
} from '../../../actions/registration-system/registration-actions';

class SchoolRegistration extends Question {
    constructor(props) {
        super(props, null, {
            valid: false,
        });
        this.schoolname = React.createRef();
        this.schoolType = React.createRef();
        this.street = React.createRef();
        this.zipcode = React.createRef();
        this.city = React.createRef();
        this.filteredCityList = [];

        this.salutation = React.createRef();
        this.firstname = React.createRef();
        this.lastname = React.createRef();
        this.position = React.createRef();
        this.emailschool = React.createRef();
        this.phone = React.createRef();
        this.email = React.createRef();
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                // get setSchoolTypeList, when it's not in Redux yet
                if (
                    this.props.schoolTypeList &&
                    !Object.keys(this.props.schoolTypeList).length
                ) {
                    return this.props
                        .dispatch(
                            fetchSchoolTypeList(this.props.authenticationData),
                        )
                        .then(null, (_e) =>
                            Promise.reject('school types not available'),
                        );
                }
            })
            .catch((e) => {
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    componentDidMount() {
        const zipcode = this.props.formdata.school_selection.values.zipcode;
        this.props.change('zipcode', zipcode);
        this.props.dispatch(fetchZipcodeCities(zipcode));
        super.componentDidMount();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.zipCodeCities !== this.props.zipCodeCities) {
            this.filteredCityList = this.props.zipCodeCities.map(
                (obj) => obj.location,
            );
            if (this.filteredCityList.length === 1) {
                // if there's only one city, the input field is disabled, set it explicitly
                this.props.change('city', this.filteredCityList[0]);
            }
        }
    }

    handleInput() {
        this.setState({}, () => {
            this.validate();
        });
    }
    validate() {
        this.setState({
            valid:
                this.schoolname.current.value &&
                this.schoolType.current.value &&
                this.street.current.value &&
                this.city.current.value &&
                this.salutation.current.value &&
                this.firstname.current.value &&
                this.lastname.current.value &&
                this.position.current.value &&
                this.validateEmail(this.emailschool.current.value) &&
                this.validatePhone(this.phone.current.value) &&
                this.validateEmailOwn(this.email.current.value),
        });
    }
    validatePhone = (value) => {
        let valid = value && PHONE_PATTERN.test(value) ? value : undefined;
        this.setState({ validPhone: !!valid });
        if (value) {
            const phoneNumber = value;
            for (let i = 0; i < phoneNumber.length; i++) {
                if (phoneNumber.charAt(i) === '-') {
                    this.setState({ validPhone: false });
                    valid = false;
                }
            }
        }
        return valid;
    };
    validateEmail = (value) => {
        const valid = value && MAIL_PATTERN.test(value) ? value : undefined;
        this.setState({ validEmail: !!valid });
        return valid;
    };
    validateEmailOwn = (value) => {
        const valid = value && MAIL_PATTERN.test(value) ? value : undefined;
        this.setState({ validEmailOwn: !!valid });
        return valid;
    };

    renderQuestion() {
        const isOneCity = this.filteredCityList.length === 1;
        return (
            <div className={style.question}>
                <div className={style.header}>
                    <h1>
                        <b>Schule</b> konnte nicht gefunden werden!
                    </h1>
                    <p>
                        Bitte geben Sie den Namen und Anschrift der Schule sowie
                        Daten zu Ihrer Person (für Rückfragen) an. Anschließend
                        prüfen wir die Angaben und nehmen die Registrierung
                        Ihrer Schule in unserer Datenbank vor. Dies erfolgt in
                        der Regel bis zum nächsten Werktag.
                    </p>
                </div>
                <fieldset>
                    <label htmlFor='schoolname'>Name der Schule</label>
                    <Field
                        component='input'
                        type='text'
                        name='schoolname'
                        maxLength='255'
                        ref={this.schoolname}
                        onChange={(_e) => this.handleInput()}
                    />

                    <label htmlFor='schoolType'>Schulform</label>
                    <Field
                        component='select'
                        name='schoolType'
                        ref={this.schoolType}
                        onChange={(_e) => this.handleInput()}
                    >
                        <option value='' hidden>
                            Bitte wählen
                        </option>
                        {this.props.schoolTypeList.map((schoolType) => (
                            <option
                                key={schoolType.name}
                                value={schoolType.name}
                            >
                                {schoolType.name}
                            </option>
                        ))}
                    </Field>

                    <label htmlFor='street'>Straße / Nr.</label>
                    <Field
                        component='input'
                        type='text'
                        name='street'
                        maxLength='120'
                        ref={this.street}
                        onChange={(_e) => this.handleInput()}
                    />

                    <label htmlFor='zipcode'>PLZ</label>
                    <Field
                        component='input'
                        type='text'
                        name='zipcode'
                        maxLength='5'
                        disabled={true}
                        onChange={(_e) => this.handleInput()}
                        ref={this.zipcode}
                    />

                    <label htmlFor='city'>Ort</label>
                    <Field
                        component='select'
                        name='city'
                        disabled={isOneCity}
                        ref={this.city}
                        onChange={(_e) => this.handleInput()}
                    >
                        {isOneCity ? (
                            <option
                                key={this.filteredCityList[0]}
                                value={this.filteredCityList[0]}
                            >
                                {this.filteredCityList[0]}
                            </option>
                        ) : (
                            <>
                                <option value='' hidden>
                                    Bitte wählen Sie eine Stadt
                                </option>
                                {this.filteredCityList.map((city) => {
                                    return (
                                        <option key={city} value={city}>
                                            {city}
                                        </option>
                                    );
                                })}
                            </>
                        )}
                    </Field>

                    <label htmlFor='emailschool'>E-Mail der Schule</label>
                    <Field
                        component='input'
                        type='email'
                        name='emailschool'
                        maxLength='255'
                        className={classNames({
                            invalid: !this.state.validEmail,
                        })}
                        validate={this.validateEmail}
                        ref={this.emailschool}
                        onChange={(_e) => this.handleInput()}
                    />

                    <hr />

                    <label htmlFor='salutation'>Anrede</label>
                    <Field
                        component='select'
                        name='salutation'
                        ref={this.salutation}
                        onChange={(_e) => this.handleInput()}
                    >
                        <option value='' hidden>
                            Bitte wählen
                        </option>
                        <option value='Frau'>Frau</option>
                        <option value='Herr'>Herr</option>
                        <option value='KeineAngabe'>Keine Angabe</option>
                    </Field>

                    <label htmlFor='lastname'>Nachname</label>
                    <Field
                        component='input'
                        type='text'
                        name='lastname'
                        maxLength='100'
                        ref={this.lastname}
                        onChange={(_e) => this.handleInput()}
                    />

                    <label htmlFor='firstname'>Vorname</label>
                    <Field
                        component='input'
                        type='text'
                        name='firstname'
                        maxLength='100'
                        ref={this.firstname}
                        onChange={(_e) => this.handleInput()}
                    />

                    <label htmlFor='position'>Position</label>
                    <Field
                        component='input'
                        type='text'
                        name='position'
                        maxLength='100'
                        ref={this.position}
                        onChange={(_e) => this.handleInput()}
                    />

                    <label htmlFor='email'>
                        E-Mail (für die Zusendung der Benachrichtigung)
                    </label>
                    <Field
                        component='input'
                        type='email'
                        name='email'
                        maxLength='200'
                        className={classNames({
                            invalid: !this.state.validEmailOwn,
                        })}
                        validate={this.validateEmailOwn}
                        ref={this.email}
                        onChange={(_e) => this.handleInput()}
                    />

                    <label htmlFor='phone'>
                        Telefon (bitte geben Sie nur Ziffern ein)
                    </label>
                    <Field
                        component='input'
                        type='tel'
                        name='phone'
                        maxLength='255'
                        className={classNames({
                            invalid: !this.state.validPhone,
                        })}
                        validate={this.validatePhone}
                        ref={this.phone}
                        onChange={(_e) => this.handleInput()}
                    />
                </fieldset>
                <StepButtons
                    back='/school-selection'
                    to='/school-registration-validate'
                    disabled={!this.state.valid}
                />
            </div>
        );
    }
}

export default reduxForm({
    form: 'school_registration',
    destroyOnUnmount: false,
})(connect((s) => ({ formdata: s.form, ...s }), null)(SchoolRegistration));
