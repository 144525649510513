export const BACKEND_URL_BASE =
    process.env.REACT_APP_IDEENEXPO_SERVER || 'https://ideenexpo.edicos.de';
// export const BACKEND_URL = BACKEND_URL_BASE + "/IdeenExpo/validator/";
export const BACKEND_URL = 'https://einladung.ideenexpo.de/IdeenExpo/validator/';

// export const PLZMAPPING_URL = BACKEND_URL_BASE + "/IdeenExpo/plzmapping/";
export const PLZMAPPING_URL =
    'http://einladung.ideenexpo.de/IdeenExpo/plzmapping/';

export const ENV = process.env.REACT_APP_ENV || 'production';

export const OPENGEODB_URL = 'http://www.fa-technik.adfc.de/code/opengeodb/';

/** Feature-Flags, um noch nicht ganz fertig implementierte Features zu verstecken
 * @type {{[k in 'banner']?: boolean'}}
 */
export const FEATURE_FLAGS = {
    banner: true,
};

/** Aktuelle Wartungseinstellungen für jeweilige Systeme.
 * @type {{[k in 'registration' | 'invitation' | 'exhibitor']?: {status: boolean, message: string}'}}
 */
export const MAINTENANCE = {
    registration: {
        status: false,
        message: 'Das Anmeldesystem wird in Kürze für Sie freigeschaltet.',
    },
};

/** Falls `true`, überspringt alle Deadline-Prüfungen und lässt damit Anfragen außerhalb definierter Zeiträume zu. */
export const IGNORE_DEADLINES = true;

// Setting the window title depending on the current app
if (process.env.REACT_APP_IDEENEXPO === 'registration') {
    window.document.title = 'Ideenexpo Anmeldung';
} else if (process.env.REACT_APP_IDEENEXPO === 'invitation') {
    window.document.title = 'Ideenexpo Einladung';
} else if (process.env.REACT_APP_IDEENEXPO === 'exhibitor') {
    window.document.title = 'Ideenexpo Ausstellerportal';
}

console.info('backend url:', BACKEND_URL);
export const APPLICATION_ID = '975';

export const GET_LIST = 'getLst';
export const GET = 'get';
export const SAVE = 'save';
export const ADD = 'insert';
export const NEW = 'getNew';
export const DELETE = 'delete';
export const PWCHANGE = 'dopasswordchange';

export const AUTHENTICATION = 'authenticationCustom';

export const PLZMAPPING = 'plzmapping';

export const IEXUSER = 'iexuser/';
export const USER = 'user/';
export const USER_GET = USER + GET;
export const USER_SAVE = USER + SAVE;
export const USER_PWCHANGE = IEXUSER + PWCHANGE;

export const PROJECT = 'projekt/';
export const PROJECT_GET = PROJECT + GET;
export const PROJECT_LIST = PROJECT + GET_LIST;

export const CONTRACT = 'vertrag/';
export const CONTRACT_GET = CONTRACT + GET;
export const CONTRACT_LIST = CONTRACT + GET_LIST;
export const CONTRACT_SAVE = CONTRACT + SAVE;

export const INVITATION = 'einladung/';
export const INVITATION_LIST = INVITATION + GET_LIST;
export const INVITATION_GET = INVITATION + GET;
export const INVITATION_SAVE = INVITATION + SAVE;
export const INVITATION_ADD = INVITATION + ADD;

export const EVENT = 'event/';
export const EVENT_GET = EVENT + GET;
export const EVENT_LIST = EVENT + GET_LIST;

export const PERSON = 'person/';
export const PERSON_LIST = PERSON + GET_LIST;
export const PERSON_GET = PERSON + GET;
export const PERSON_SAVE = PERSON + SAVE;
export const PERSON_ADD = PERSON + ADD;

export const PARTICIPANT = 'teilnehmer/';
export const PARTICIPANT_LIST = PARTICIPANT + GET_LIST;
export const PARTICIPANT_GET = PARTICIPANT + GET;
export const PARTICIPANT_SAVE = PARTICIPANT + SAVE;
export const PARTICIPANT_ADD = PARTICIPANT + ADD;

export const WORKSHOP = 'veranstaltung/';
export const WORKSHOP_LIST = WORKSHOP + GET_LIST;
export const WORKSHOP_GET = WORKSHOP + GET;
export const WORKSHOP_SAVE = WORKSHOP + SAVE;
export const WORKSHOP_ADD = WORKSHOP + ADD;
export const WORKSHOP_NEW = WORKSHOP + NEW;
export const WORKSHOP_DELETE = WORKSHOP + DELETE;

export const EXHIBIT = 'exponat/';
export const EXHIBIT_LIST = EXHIBIT + GET_LIST;
export const EXHIBIT_GET = EXHIBIT + GET;
export const EXHIBIT_SAVE = EXHIBIT + SAVE;
export const EXHIBIT_ADD = EXHIBIT + ADD;
export const EXHIBIT_NEW = EXHIBIT + NEW;
export const EXHIBIT_DELETE = EXHIBIT + DELETE;

export const BOOTH = 'stand/';
export const BOOTH_LIST = BOOTH + GET_LIST;
export const BOOTH_GET = BOOTH + GET;
export const BOOTH_SAVE = BOOTH + SAVE;
export const BOOTH_ADD = BOOTH + ADD;

export const BOOTH_FEATURE = 'standausstattung/';
export const BOOTH_FEATURE_LIST = BOOTH_FEATURE + GET_LIST;
export const BOOTH_FEATURE_GET = BOOTH_FEATURE + GET;

export const COMPANY = 'firma/';
export const COMPANY_LIST = COMPANY + GET_LIST;
export const COMPANY_GET = COMPANY + GET;
export const COMPANY_SAVE = COMPANY + SAVE;
export const COMPANY_ADD = COMPANY + ADD;

export const FEATURE = 'ausstattung/';
export const FEATURE_LIST = FEATURE + GET_LIST;
export const FEATURE_GET = FEATURE + GET;
export const FEATURE_SAVE = FEATURE + SAVE;
export const FEATURE_ADD = FEATURE + ADD;

export const EXHIBITOR = 'ausstellerbenutzer/';
export const EXHIBITOR_LIST = EXHIBITOR + GET_LIST;
export const EXHIBITOR_GET = EXHIBITOR + GET;

export const CUSTOMLIST = 'lstdef/';
export const CUSTOMLIST_LIST = CUSTOMLIST + GET_LIST;
export const CUSTOMLIST_GET = CUSTOMLIST + GET;
export const CUSTOMLIST_SAVE = CUSTOMLIST + SAVE;

export const THEME_WORLD = 'themenwelt/';
export const THEME_WORLD_LIST = THEME_WORLD + GET_LIST;
export const THEME_WORLD_GET = THEME_WORLD + GET;

export const TRAINING = 'ausbildungsberuf/';
export const TRAINING_LIST = TRAINING + GET_LIST;
export const TRAINING_GET = TRAINING + GET;
export const TRAINING_SAVE = TRAINING + SAVE;
export const TRAINING_ADD = TRAINING + ADD;
export const TRAINING_NEW = TRAINING + NEW;
export const TRAINING_DELETE = TRAINING + DELETE;

export const STUDYFIELD = 'studienrichtung/';
export const STUDYFIELD_LIST = STUDYFIELD + GET_LIST;
export const STUDYFIELD_GET = STUDYFIELD + GET;
export const STUDYFIELD_SAVE = STUDYFIELD + SAVE;
export const STUDYFIELD_ADD = STUDYFIELD + ADD;
export const STUDYFIELD_NEW = STUDYFIELD + NEW;

export const DUALSTUDY = 'dualesstudium/';
export const DUALSTUDY_LIST = DUALSTUDY + GET_LIST;
export const DUALSTUDY_GET = DUALSTUDY + GET;
export const DUALSTUDY_SAVE = DUALSTUDY + SAVE;
export const DUALSTUDY_ADD = DUALSTUDY + ADD;
export const DUALSTUDY_NEW = DUALSTUDY + NEW;

export const BANNER_PRINT = 'bannerprint/';
export const BANNER_PRINT_LIST = BANNER_PRINT + GET_LIST;
export const BANNER_PRINT_GET = BANNER_PRINT + GET;
export const BANNER_PRINT_SAVE = BANNER_PRINT + SAVE;
export const BANNER_PRINT_ADD = BANNER_PRINT + ADD;
export const BANNER_PRINT_NEW = BANNER_PRINT + NEW;
export const BANNER_PRINT_DELETE = BANNER_PRINT + DELETE;

export const BANNER_DIGITAL = 'bannerdigital/';
export const BANNER_DIGITAL_LIST = BANNER_DIGITAL + GET_LIST;
export const BANNER_DIGITAL_GET = BANNER_DIGITAL + GET;
export const BANNER_DIGITAL_SAVE = BANNER_DIGITAL + SAVE;
export const BANNER_DIGITAL_ADD = BANNER_DIGITAL + ADD;
export const BANNER_DIGITAL_NEW = BANNER_DIGITAL + NEW;
export const BANNER_DIGITAL_DELETE = BANNER_DIGITAL + DELETE;

export const BOARD = 'exponattafel/';
export const BOARD_LIST = BOARD + GET_LIST;
export const BOARD_GET = BOARD + GET;
export const BOARD_SAVE = BOARD + SAVE;
export const BOARD_ADD = BOARD + ADD;
export const BOARD_NEW = BOARD + NEW;
export const BOARD_DELETE = BOARD + DELETE;

export const STAGE = 'buehne/';
export const STAGE_LIST = STAGE + GET_LIST;
export const STAGE_GET = STAGE + GET;
export const STAGE_SAVE = STAGE + SAVE;
export const STAGE_ADD = STAGE + ADD;

export const SHOWSTAGE = 'showbuehne/';
export const SHOWSTAGE_LIST = SHOWSTAGE + GET_LIST;
export const SHOWSTAGE_GET = SHOWSTAGE + GET;
export const SHOWSTAGE_SAVE = SHOWSTAGE + SAVE;
export const SHOWSTAGE_ADD = SHOWSTAGE + ADD;
export const SHOWSTAGE_NEW = SHOWSTAGE + NEW;
export const SHOWSTAGE_DELETE = SHOWSTAGE + DELETE;

export const STAGESEVEN = 'buehnesieben/';
export const STAGESEVEN_LIST = STAGESEVEN + GET_LIST;
export const STAGESEVEN_GET = STAGESEVEN + GET;
export const STAGESEVEN_SAVE = STAGESEVEN + SAVE;
export const STAGESEVEN_ADD = STAGESEVEN + ADD;
export const STAGESEVEN_NEW = STAGESEVEN + NEW;
export const STAGESEVEN_DELETE = STAGESEVEN + DELETE;

export const CAREERSTAGE = 'karrierebuehne/';
export const CAREERSTAGE_LIST = CAREERSTAGE + GET_LIST;
export const CAREERSTAGE_GET = CAREERSTAGE + GET;
export const CAREERSTAGE_SAVE = CAREERSTAGE + SAVE;
export const CAREERSTAGE_ADD = CAREERSTAGE + ADD;
export const CAREERSTAGE_NEW = CAREERSTAGE + NEW;
export const CAREERSTAGE_DELETE = CAREERSTAGE + DELETE;

export const ORGANISATION = 'organisation/';
export const ORGANISATION_LIST = ORGANISATION + GET_LIST;
export const ORGANISATION_GET = ORGANISATION + GET;
export const ORGANISATION_NEW = ORGANISATION + NEW;
export const ORGANISATION_ADD = ORGANISATION + ADD;
export const ORGANISATION_SAVE = ORGANISATION + SAVE;

export const PASSES = 'ausstellerausweis/';
export const PASSES_LIST = PASSES + GET_LIST;
export const PASSES_GET = PASSES + GET;
export const PASSES_SAVE = PASSES + SAVE;
export const PASSES_ADD = PASSES + ADD;
export const PASSES_NEW = PASSES + NEW;
export const PASSES_DELETE = PASSES + DELETE;

export const CATERING = 'ausstellercatering/';
export const CATERING_LIST = CATERING + GET_LIST;
export const CATERING_GET = CATERING + GET;
export const CATERING_SAVE = CATERING + SAVE;
export const CATERING_ADD = CATERING + ADD;
export const CATERING_NEW = CATERING + NEW;

export const STORAGE = 'lagerflaeche/';
export const STORAGE_LIST = STORAGE + GET_LIST;
export const STORAGE_GET = STORAGE + GET;
export const STORAGE_SAVE = STORAGE + SAVE;
export const STORAGE_ADD = STORAGE + ADD;
export const STORAGE_NEW = STORAGE + NEW;

export const STELE = 'stele/';
export const STELE_LIST = STELE + GET_LIST;
export const STELE_GET = STELE + GET;
export const STELE_SAVE = STELE + SAVE;
export const STELE_ADD = STELE + ADD;
export const STELE_NEW = STELE + NEW;
export const STELE_DELETE = STELE + DELETE;

export const STELETYPE = 'stelentyp/';
export const STELETYPE_LIST = STELETYPE + GET_LIST;
export const STELETYPE_GET = STELETYPE + GET;
export const STELETYPE_SAVE = STELETYPE + SAVE;
export const STELETYPE_ADD = STELETYPE + ADD;
export const STELETYPE_NEW = STELETYPE + NEW;

export const BOOTH2FEATURE = 'stand2ausstattung/';
export const BOOTH2FEATURE_LIST = BOOTH2FEATURE + GET_LIST;
export const BOOTH2FEATURE_GET = BOOTH2FEATURE + GET;

export const INFODESK = 'infotresen/';
export const INFODESK_LIST = INFODESK + GET_LIST;
export const INFODESK_GET = INFODESK + GET;
export const INFODESK_SAVE = INFODESK + SAVE;
export const INFODESK_ADD = INFODESK + ADD;
export const INFODESK_NEW = INFODESK + NEW;

export const PRESENTATIONMODULE = 'praesentationsmodul/';
export const PRESENTATIONMODULE_LIST = PRESENTATIONMODULE + GET_LIST;
export const PRESENTATIONMODULE_GET = PRESENTATIONMODULE + GET;
export const PRESENTATIONMODULE_SAVE = PRESENTATIONMODULE + SAVE;
export const PRESENTATIONMODULE_ADD = PRESENTATIONMODULE + ADD;
export const PRESENTATIONMODULE_NEW = PRESENTATIONMODULE + NEW;

export const COMPANY2SITE = 'firmaausbildungsstandort/';
export const COMPANY2SITE_LIST = COMPANY2SITE + GET_LIST;
export const COMPANY2SITE_GET = COMPANY2SITE + GET;
export const COMPANY2SITE_SAVE = COMPANY2SITE + SAVE;
export const COMPANY2SITE_ADD = COMPANY2SITE + ADD;
export const COMPANY2SITE_NEW = COMPANY2SITE + NEW;
export const COMPANY2SITE_DELETE = COMPANY2SITE + DELETE;

export const USER2ROLE = 'user2rolle/';
export const USER2ROLE_LIST = USER2ROLE + GET_LIST;
export const USER2ROLE_GET = USER2ROLE + GET;

export const SITE = 'ort/';
export const SITE_LIST = SITE + GET_LIST;
export const SITE_GET = SITE + GET;

export const GUEST = 'begleiter/';
export const GUEST_LIST = GUEST + GET_LIST;
export const GUEST_GET = GUEST + GET;
export const GUEST_ADD = GUEST + ADD;
export const GUEST_SAVE = GUEST + SAVE;
export const GUEST_DELETE = GUEST + DELETE;

export const COMPANY2PERSON = 'firma2person/';
export const COMPANY2PERSON_LIST = COMPANY2PERSON + GET_LIST;
export const COMPANY2PERSON_GET = COMPANY2PERSON + GET;

export const SCHOOL = 'schule/';
export const SCHOOL_LIST = SCHOOL + GET_LIST;
export const SCHOOL_GET = SCHOOL + GET;
export const SCHOOL_ADD = SCHOOL + ADD;

// Roles in Backend that determine whether you are authorized for a
// component or not
export const BOOTHAUTHROLE = 'MA_Aussteller_Stände';
export const WORKSHOPAUTHROLE = 'MA_Aussteller_Veranstaltungen';
export const LIVEPROGRAMAUTHROLE = 'MA_Aussteller_LiveProgramm';
export const CLUBZUKUNFTAUTHROLE = 'MA_Aussteller_ClubZukunft';
export const CAREERSTAGEAUTHROLE = 'MA_Aussteller_KarriereBuehne';
export const ORGANISATIONAUTHROLE = 'MA_Aussteller_Organisation';

export const MAX_GROUP = 40;

export const STELETYPE_50CM_4XTEXT = 'Infostele 50cm Variante 4xText';
export const STELETYPE_50CM_2XTEXT = 'Infostele 50cm Variante 2xText/2xBild';
export const STELETYPE_150CM_IMG = 'Infostele 150cm Variante Nur Grafik';
export const STELETYPE_150CM_TEXTIMG =
    'Infostele 150cm Variante Grafik und Text';
export const STELETYPE_BERUFSINFOSTELE = 'Berufsinfostele';
export const STELETYPE_50CM_BERUFSINFOS_2XTEXT =
    'Infostele 50cm mit Berufsinfos Variante 2xText/2xBild';
export const STELETYPE_INFOTRESENBERUFSINFOS = 'Infotresen mit Berufsinfostele';

export const AUSSTATTUNG_TYPE_STELE = 'Stele';
export const AUSSTATTUNG_TYPE_EXPONAT_TAFEL = 'Exponat';
export const AUSSTATTUNG_TYPE_INFOTRESEN = 'Infotresen';
export const AUSSTATTUNG_TYPE_PRAESENTATION_MODUL = 'Präsentationsmodul';

export const DATETIME_PATTERN = /^\d{4}-\d{2}-\d{2}.*$/;
// 								   2022-  02 -  14 (beliebige Zeichen in beliebiger Anzahl)

// see: https://stackoverflow.com/a/201378/1930535
export const MAIL_PATTERN =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const PHONE_PATTERN = /^[-0-9()]+[/]?[-0-9]+$/;

export const ZIPCODE_PATTERN = /^\d{5}$/;
export const SCHOOL_NUMBER_REGEX = /^\d{4,5}$/;
export const LOCATION_PATTERN =
    /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;

export const REFRESH_LOGIN_TIMEOUT = 1000 * 60 * 15; // Refresh after 15 minutes

export const RADIO_VALUE_NO = 'no';
export const RADIO_VALUE_YES = 'yes';

export const RADIO_VALUE_LIVE_PROGRAM_DISABLED = 'liveprogram_disabled';
export const RADIO_VALUE_LIVE_PROGRAM_ENABLED = 'liveprogram_enabled';

export function INVITATION_EMPTY(login) {
    return {
        type: 'tpEinladung',
        accessDataEdit: 0,
        login: login,
        rcLst: {
            GsRCLstItem: [
                {
                    msg_ID: 2,
                    params: [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    ],
                    type: 3,
                },
            ],
        },
        saveWithWarnings: false,
        selected: false,
        transactionId: 0,
        workflowTaskId: 0,
        DELETED: 0,
        ID: 0,
        MANDANT_NR: 1,
        USER_NR_CHANGE: 0,
        USER_NR_NEW: 0,
        idEvent: 0,
        idPerson: 0,
        idProjekt: 0,
        idRolle: -1,
        idStatus: 0,
        kzVIP: 0,
        beschreibung: '',
        tp_EinladungControl: {
            readHistPerField: false,
            active_Einladung_2_BegleiterLst: true,
            active_Event_2_Einladung: false,
            active_Event_2_EinladungLstTo: false,
            active_Person_2_Einladung: false,
            active_Person_2_EinladungLstTo: false,
            active_Projekt_2_Einladung: false,
            active_Projekt_2_EinladungLstTo: false,
        },
    };
}

export const initialState = {
    step: 0,
    form: {
        personals: {},
        guests: {
            values: {
                guests: [],
            },
        },
        referrals: {
            values: {
                referrals: [],
            },
        },
        plzMapping: {
            Bundesland: '',
            PLZ: '',
        },
        school_selection: {
            values: {
                zipCode: '',
                federalState: '',
                isInLowerSaxony: false,
                schoolNumber: '',
            },
        },
        boardsedit: {},
        booths: {},
        careermarkettraining: {},
        careerstage: {},
        careerstageeditbasis: {},
        careerstageeditcontact: {},
        contactdatainternet: {},
        contactdatapupils: {},
        contactdatastudents: {},
        defaultlogo: {},
        exhibitorcatering: {},
        exhibitorpasses: {},
        exhibitorPassEdit: {},
        exhibitseditbasis: {},
        exhibitseditdescription: {},
        exhibitseditspecifications: {},
        furnishingsnew: {},
        liveprogram: {},
        login: {},
        noliveprogram: {},
        showstage: {},
        showstageeditbasis: {},
        showstageeditcontact: {},
        sitesedit: {},
        stageseven: {},
        stageseveneditbasis: {},
        stageseveneditcontact: {},
        stelesedit: {},
        stelesnew: {},
        storage: {},
        study: {},
        studyfields: {},
        technic: {},
        trainingnew: {},
        workshopeditbasis: {},
        workshopeditequipment: {},
        workshopeditschedule: {},
        workshops: {},
        bannerEdit: {},
    },
    groupDefaults: {
        girls: 0,
        boys: 0,
        leaders: 1,
    },
    schoolTypeList: [],
    schoolTrackList: [],
    allowanceList: [],
    arrivalDayList: [],
    editedAnmeldung: { id: -1, index: -1, anmeldecode: '' },
    arrivalTimeList: [],
    bezugsgruppe: [],
    board: [],
    booth2feature: [],
    booth: [],
    boothFeatures: [],
    careerstage: [],
    catering: [],
    classLevelList: [],
    company2person: [],
    company2site: [],
    company: [],
    deadlines: [],
    departureTimeSchoolList: [],
    dualstudy: [],
    dualstudydef: [],
    event: [],
    exhibit: [],
    exhibitor: [],
    exponatzg: [],
    feature: [],
    guests: [],
    infodesk: [],
    invitation: [],
    organisation: [],
    passes: [],
    person: [],
    presentationmodule: [],
    project: [],
    showstage: [],
    site: [],
    stageseven: [],
    stele: [],
    steletype: [],
    storage: [],
    studyfield: [],
    studyfielddef: [],
    substitute: [],
    themeWorld: [],
    training: [],
    trainingdef: [],
    transportList: [],
    user2role: [],
    veranstzielgruppe: [],
    veranstzp: [],
    workshop: [],
    bannerPrintList: [],
    bannerDigitalList: [],
    firmaKategorieList: [],
};
